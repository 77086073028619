import fetch from "auth/FetchInterceptor";

const JwtAuthService = {};

JwtAuthService.login = async (data) => {
  try {
    const fd = new FormData();
    fd.append("grant_type", "password");
    fd.append("scope", "openid profile email Former role");
    fd.append("client_id", "Former_App");
    fd.append("password", data.password);
    fd.append("username", data.userName);
    const res = await fetch.post("/connect/token", fd, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return res;
  } catch (err) {
    throw err;
  }
};
JwtAuthService.get = async (id) => {
  try {
    const res = await fetch.get(`/api/app/formerIdentity/${id}`);
    return res;
  } catch (error) {}
};

JwtAuthService.forgotPassword = async (email) => {
  return await fetch.post("/api/app/formerIdentity/forgotPassword", {
    email,
  });
};
export default JwtAuthService;
