import { atom } from "recoil";

export const workbenchListState = atom({
  key: "workbenchListState",
  default: [],
});
export const workbenchListFilterState = atom({
  key: "workbenchListFilterState",
  default: null,
});
export const employeeListState = atom({
  key: "employeeListState",
  default: [],
});
export const employeeListFilterState = atom({
  key: "employeeListFilterState",
  default: null,
});

//unit
export const unitListState = atom({
  key: "unitListState",
  default: [],
});
export const unitListFilterState = atom({
  key: "unitListFilterState",
  default: null,
});
export const workbenchRecipeListFilterState = atom({
  key: " workbenchRecipeListFilterState",
  default: null,
});
export const workbenchRecipeListState = atom({
  key: "workbenchRecipeListState",
  default: [],
});
