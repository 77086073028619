import { THEME_CONFIG } from "configs/AppConfig";
import { atom } from "recoil";
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from "../constants/ThemeConstant";
export const navCollapsedState = atom({
  key: "navCollapsedState",
  default: THEME_CONFIG.navCollapsed,
});

export const sideNavThemeState = atom({
  key: "sideNavThemeState",
  default: THEME_CONFIG.sideNavTheme,
});
export const localeState = atom({
  key: "localeState",
  default: THEME_CONFIG.locale,
});
export const navTypeState = atom({
  key: "navTypeState",
  default: THEME_CONFIG.navType,
});
export const topNavColorState = atom({
  key: "topNavColorState",
  default: THEME_CONFIG.topNavColor,
});

export const headerNavColorState = atom({
  key: "headerNavColorState",
  default: THEME_CONFIG.headerNavColor,
});

export const mobileNavToggleState = atom({
  key: "mobileNavToggleState",
  default: THEME_CONFIG.mobileNav,
});
