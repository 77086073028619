import antdTr from "antd/es/locale/tr_TR";
import enMsg from "../locales/en_US.json";

const trLang = {
  antd: antdTr,
  locale: "tr",
  messages: {
    ...enMsg,
  },
};
export default trLang;
