import {selector} from "recoil";
import {processListFilterState, processListState} from "./process.atoms";


export const getProcessList = selector({
    key: "getProcessList",
    get: ({ get }) => {
        const list = get(processListState);
        const filter = get(processListFilterState);
        if (!filter) return list;
        const filteredItems = list.filter(
            (item) =>
                item.name.toLowerCase().includes(filter.toLowerCase())
        );
        return filteredItems;
    },
});