import { selector } from "recoil";
import {
  workbenchListState,
  workbenchListFilterState,
  employeeListFilterState,
  employeeListState,
  unitListState,
  unitListFilterState,
} from "./definitons.atoms";

export const getWorkbenchList = selector({
  key: "getWorkbenchNotfList",
  get: ({ get }) => {
    const list = get(workbenchListState);
    const filter = get(workbenchListFilterState);
    if (!filter) return list;
    const filteredItems = list.filter(
      (item) =>
        item.code.toLowerCase().includes(filter.toLowerCase()) ||
        item.name.toLowerCase().includes(filter.toLowerCase())
    );
    return filteredItems;
  },
});

export const getEmployeeList = selector({
  key: "getEmployeeNotfList",
  get: ({ get }) => {
    const list = get(employeeListState);
    const filter = get(employeeListFilterState);
    if (!filter) return list;
    const filteredItems = list.filter(
      (item) =>
        item.name.toLowerCase().includes(filter.toLowerCase()) ||
        item.surname.toLowerCase().includes(filter.toLowerCase()) ||
        item.jobDefinition.toLowerCase().includes(filter.toLowerCase())
    );
    return filteredItems;
  },
});

export const getUnitList = selector({
  key: "getUnitNotfList",
  get: ({ get }) => {
    const list = get(unitListState);
    const filter = get(unitListFilterState);
    if (!filter) return list;
    const filteredItems = list.filter(
      (item) =>
        item.name.toLowerCase().includes(filter.toLowerCase()) ||
        item.jobDescription.toLowerCase().includes(filter.toLowerCase())
    );
    return filteredItems;
  },
});
