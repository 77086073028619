import React from "react";
import { NAV_TYPE_TOP } from "constants/ThemeConstant";
import utils from "utils";
import MenuContent from "./MenuContent";
import { useRecoilValue } from "recoil";
import { getTopNavColorState } from "store/selectors";

export const TopNav = ({ localization = true }) => {
  const props = { localization };
  const topNavColor = useRecoilValue(getTopNavColorState);
  const mode = utils.getColorContrast(topNavColor);

  return (
    <div className={`top-nav ${mode}`} style={{ backgroundColor: topNavColor }}>
      <div className="top-nav-wrapper">
        <MenuContent type={NAV_TYPE_TOP} {...props} />
      </div>
    </div>
  );
};

export default TopNav;
