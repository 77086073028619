import axios from "axios";
import { API_BASE_URL, SITE_URL } from "configs/AppConfig";
import history from "../history";
import { notification } from "antd";
import { TOKEN_NAME } from "constants/ApiConstant";

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000,
});

// Config
const ENTRY_ROUTE = "auth/login";
const TOKEN_PAYLOAD_KEY = "authorization";
const PUBLIC_REQUEST_KEY = "public-request";

// API Request interceptor
service.interceptors.request.use(
  (config) => {
    const jwtToken = localStorage.TOKEN;

    if (jwtToken) {
      config.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${jwtToken}`;
    }

    //   if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
    // 		history.push(ENTRY_ROUTE)
    // 		window.location.reload();
    //   }

    return config;
  },
  (error) => {
    // Do something with request error here
    notification.error({
      message: "Error",
    });
    Promise.reject(error);
  }
);

// API respone interceptor
service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    let notificationParam = {
      message: "",
    };

    return;
    // Remove token and redirect
    if (error.response.status === 401) {
      notificationParam.message = "HATA!";
      notificationParam.description = "Giriş yapmanız gerekmektedir";
      // history.push(ENTRY_ROUTE);
      window.location.href = `${SITE_URL}${ENTRY_ROUTE}`;
      localStorage.removeItem(TOKEN_NAME);
    }
    if (error.response.status === 403) {
      notificationParam.message = "UYARI!";
      notificationParam.description = "Bu işlemi yapmaya yetkiniz yoktur";
      history.push("/auth/forbidden");
    }

    if (error.response.status === 404) {
      notificationParam.message = "Bulunamadı!";
    }

    if (error.response.status === 500) {
      notificationParam.message = "Internal Server Error";
    }

    if (error.response.status === 508) {
      notificationParam.message = "Time Out";
    }
    // if (error.response.status === 400) {
    //   notificationParam.message = "Hatalı istek";
    // }
    if (notificationParam.message) {
      notification.error(notificationParam);
    }

    return Promise.reject(error);
  }
);

export default service;
