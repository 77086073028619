import React, { useEffect, useState } from "react";
import { Menu, Dropdown, Badge, Avatar, List, Button, Empty } from "antd";
import {
  MailOutlined,
  BellOutlined,
  WarningOutlined,
  MailTwoTone,
  CheckCircleOutlined,
} from "@ant-design/icons";
import Flex from "components/shared-components/Flex";
import { useRecoilState } from "recoil";
import { authState, getUserNotifications } from "store/atoms";
import JwtDecode from "jwt-decode";
import NotificationService from "services/NotificationService";

const getNotificationBody = (list) => {
  return list.length > 0 ? (
    <List
      size="small"
      itemLayout="horizontal"
      dataSource={list}
      renderItem={(item) => (
        <List.Item className="list-clickable">
          <Flex alignItems="center">
            <div className="pr-3">
              <Avatar icon={<MailTwoTone />} />
            </div>
            <div className="mr-3">
              <span className="font-weight-bold text-dark">{item.title} </span>
              <span className="text-gray-light">{item.description}</span>
            </div>
            <small className="ml-auto">{item.time}</small>
          </Flex>
        </List.Item>
      )}
    />
  ) : (
    <div className="empty-notification">
      <Empty description="Tüm bildirimler okunmuştur" />
    </div>
  );
};

export const NavNotification = () => {
  const [visible, setVisible] = useState(false);
  const [, setLoading] = useState(false);
  const [userId, setUserId] = useState("");
  const [notifications, setNotifications] = useRecoilState(
    getUserNotifications
  );
  const [token] = useRecoilState(authState);
  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };
  useEffect(() => {
    if (token) {
      const decode = JwtDecode(token);
      setUserId("");
      setLoading(true);
      NotificationService.getUserNotificationsAsync("").then((res) => {
        setNotifications(res);
        setLoading(false);
      });
    }
  }, []);

  const notificationList = (
    <div className="nav-dropdown nav-notification">
      <div className="nav-notification-header d-flex justify-content-between align-items-center">
        <h4 className="mb-0">Bildirimler</h4>
        <Button
          type="link"
          onClick={() => {
            if (notifications.length) {
              setNotifications([]);
              NotificationService.readAllNotificationsAsync(userId);
            }
          }}
          size="small"
        >
          Temizle{" "}
        </Button>
      </div>
      <div className="nav-notification-body">
        {getNotificationBody(notifications)}
      </div>
      {notifications.length > 0 ? (
        <div className="nav-notification-footer">
          {/* <a className="d-block" href="#/">
            View all
          </a> */}
        </div>
      ) : null}
    </div>
  );

  return (
    <Dropdown
      placement="bottomRight"
      overlay={notificationList}
      onVisibleChange={handleVisibleChange}
      visible={visible}
      trigger={["click"]}
    >
      <Menu mode="horizontal">
        <Menu.Item>
          <Badge count={notifications.length}>
            <BellOutlined className="nav-icon mx-auto" type="bell" />
          </Badge>
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

export default NavNotification;
