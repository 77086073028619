import { atom } from "recoil";

export const processListState = atom({
    key: "processListState",
    default: [],
});

export const processListFilterState = atom({
    key: "processListFilterState",
    default: null,
});

