import { atom } from "recoil";

export const authState = atom({
  key: "authState",
  default: localStorage.TOKEN,
});
export const loginRequestState = atom({
  key: "loginRequestState",
  default: null,
});
export const roleState = atom({
  key: "roleState",
  default: [],
});
