import { Role } from "configs/RoleConfig";

export const AppRoutes = [
  {
    path: "/home",
    component: "./home",
    roles: [],
    exact: false,
  },
  {
    path: "/definitions/workbench",
    component: "./definitions/workbench",
    roles: [Role.admin, Role.planlama],
    exact: true,
  },
  {
    path: "/definitions/workbench/:id",
    component: "./definitions/workbench.detail.js",
    roles: [Role.admin, Role.planlama],
    exact: true,
  },
  {
    path: "/definitions/employee",
    component: "./definitions/employee",
    roles: [],
    exact: false,
  },
  {
    path: "/definitions/unit",
    component: "./definitions/unit",
    roles: [],
    exact: false,
  },
  {
    path: "/company",
    component: "./company/company.js",
    roles: [Role.satis, Role.admin, Role.planlama],
    exact: false,
  },
  {
    path: "/supplier",
    component: "./supplier/supplier.js",
    roles: [Role.admin, Role.planlama],
    exact: false,
  },
  {
    path: "/process",
    component: "./process/process.js",
    roles: [Role.admin, Role.planlama],
    exact: false,
  },
  {
    path: "/operation",
    component: "./operation/operation.js",
    roles: [],
    exact: false,
  },
  {
    path: "/calendar",
    component: "./utils/calendar.js",
    roles: [],
    exact: false,
  },
  {
    path: "/chat",
    component: "./utils/chat.js",
    roles: [],
    exact: false,
  },
  {
    path: "/chat/:id",
    component: "./utils/chat.js",
    roles: [],
    exact: false,
  },
  {
    path: "/mail",
    component: "./utils/mail.js",
    roles: [],
    exact: false,
  },
  {
    path: "/order",
    component: "./order/order.js",
    roles: [],
    exact: true,
  },
  {
    path: "/order/add",
    component: "./order/order.add.js",
    roles: [],
    exact: true,
  },
  {
    path: "/order/add/:id",
    component: "./order/order.add.js",
    roles: [Role.planlama, Role.admin, Role.satis],
    exact: true,
  },
];
