import {
  DashboardOutlined,
  SettingOutlined,
  DesktopOutlined,
  UsergroupAddOutlined,
  DropboxOutlined,
  SisternodeOutlined,
  CalendarOutlined,
  MailOutlined,
  WechatOutlined,
  DollarOutlined,
  PartitionOutlined,
  BlockOutlined,
  SketchOutlined,
} from "@ant-design/icons";
import { Role } from "./RoleConfig";

const dashBoardNavTree = [
  {
    key: "home",
    path: "/app/home",
    title: "home",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [],
    roles: [],
  },

  {
    key: "utils",
    title: "utils",
    breadcrumb: true,
    submenu: [
      {
        key: "calendar",
        title: "calendar",
        path: "/app/calendar",
        icon: CalendarOutlined,
        breadcrumb: true,
        submenu: [],
        roles: [],
      },
      {
        key: "mail",
        title: "mail",
        path: "/app/mail",
        icon: MailOutlined,
        breadcrumb: true,
        submenu: [],
        roles: [],
      },
      {
        key: "chat",
        title: "chat",
        path: "/app/chat",
        icon: WechatOutlined,
        breadcrumb: true,
        submenu: [],
        roles: [],
      },
    ],
    roles: [],
  },
  {
    key: "manager",
    title: "manager",
    breadcrumb: true,
    submenu: [
      {
        key: "company",
        title: "companies",
        path: "/app/company",
        icon: SketchOutlined,
        breadcrumb: true,
        submenu: [],
        roles: [Role.satis, Role.admin],
      },
      {
        key: "order",
        path: "/app/order",
        title: "orders",
        icon: DollarOutlined,
        breadcrumb: true,
        submenu: [],
        roles: [],
      },
    ],
    roles: [],
  },
  {
    key: "definitions",
    title: "definitions",
    path: "/app/definitions",
    icon: SettingOutlined,
    breadcrumb: true,
    roles: [Role.admin, Role.insankaynakları, Role.planlama],

    submenu: [
      {
        key: "supplier",
        title: "suppliers",
        path: "/app/supplier",
        icon: SisternodeOutlined,
        breadcrumb: true,
        submenu: [],
        roles: [Role.admin, Role.planlama],
      },
      {
        key: "process",
        title: "processes",
        path: "/app/process",
        icon: PartitionOutlined,
        breadcrumb: true,
        submenu: [],
        roles: [Role.admin, Role.planlama],
      },
      {
        key: "operation",
        title: "operations",
        path: "/app/operation",
        icon: BlockOutlined,
        breadcrumb: true,
        submenu: [],
        roles: [Role.admin, Role.planlama],
      },
      {
        key: "workbench",
        title: "workbenches",
        path: "/app/definitions/workbench",
        icon: DesktopOutlined,
        breadcrumb: true,
        submenu: [],
        roles: [Role.admin, Role.planlama],
      },
      {
        key: "employee",
        title: "employees",
        path: "/app/definitions/employee",
        icon: UsergroupAddOutlined,
        breadcrumb: true,
        submenu: [],
        roles: [Role.admin, Role.planlama],
      },
      {
        key: "unit",
        title: "units",
        path: "/app/definitions/unit",
        icon: DropboxOutlined,
        breadcrumb: true,
        submenu: [],
        roles: [Role.insankaynakları, Role.admin, Role.planlama],
      },
    ],
  },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
