const dev = {
  API_ENDPOINT_URL: "https://former.ronodev.net",
  //API_ENDPOINT_URL: "https://formerapi.azurewebsites.net/",
  SITE_URL: "https//localhost:3000/",
};

const prod = {
  API_ENDPOINT_URL:
    "https://formerhttpapihost20201128113220.azurewebsites.net/",
  SITE_URL: "https://former.ronosoft.com/",
};

const test = {
  API_ENDPOINT_URL:
    "https://formerhttpapihost20201128113220.azurewebsites.net/",
  SITE_URL: "https://former.ronosoft.com/",
};

const getEnv = () => {
  switch (process.env.NODE_ENV) {
    case "development":
      return dev;
    case "production":
      return prod;
    case "test":
      return test;
    default:
      break;
  }
};

export const env = getEnv();
