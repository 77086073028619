import React from "react";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
  useParams,
  Link,
} from "react-router-dom";
import AppLayout from "layouts/app-layout";
import AuthLayout from "layouts/auth-layout";
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { Button, ConfigProvider, Result } from "antd";
import { useRecoilState, useRecoilValue } from "recoil";
import { getLocaleState } from "store/selectors";
import { getAuthState, getDecodedJwtState } from "store/auth.selectors";
import moment from "moment";
import "moment/locale/tr";
import FormerUtil from "utils/customUtils";

moment.locale("tr");

const PrivateRoute = ({ children }) => {
  return children;
  const auth = useRecoilValue(getAuthState);
  const [user, _] = useRecoilState(getDecodedJwtState);
  if (!auth) {
    return <Redirect to="/auth/login" />;
  } else {
    const info = FormerUtil.getCurrentRouteInfo();
    if (!info) {
      return <Redirect to="/app/home" />;
    } else if (info.roles.length) {
      let intersection = user.role.filter((x) => info.roles.includes(x));
      if (intersection.length) return children;
      return (
        <Result
          status="403"
          title="403"
          subTitle="Bu sayfaya giriş yetkiniz yoktur ! "
          extra={
            <Link to={"/app/home"}>
              <Button type="primary">Ana Sayfaya Dön</Button>
            </Link>
          }
        />
      );
    } else {
      return children;
    }
  }
};
export const Views = () => {
  const locale = useRecoilValue(getLocaleState);
  const currentAppLocale = AppLocale[locale];
  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <ConfigProvider locale={currentAppLocale.antd}>
        <Switch>
          <Route exact path="/">
            <Redirect to="/app" />
          </Route>
          <Route path="/auth">
            <AuthLayout />
          </Route>
          <Route path="/app">
            <PrivateRoute>
              <AppLayout />
            </PrivateRoute>
          </Route>
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  );
};

export default withRouter(Views);
