import fetch from "auth/FetchInterceptor";
const URL = "api/app/notification";

const NotificationService = {
  getUserNotificationsAsync: (userId) => {
    try {
      return fetch.get(`${URL}/userNotifications/${userId}`);
    } catch (error) {}
  },
  readAllNotificationsAsync: (userId) => {
    try {
      return fetch.get(`${URL}/readAllUserNotifications/${userId}`);
    } catch (error) {}
  },
};

export default NotificationService;
