import { selector } from "recoil";
import { authState } from "./auth.atoms";
import JwtDecode from "jwt-decode";
import JwtAuthService from "services/JwtAuthService";
export const getAuthState = selector({
  key: "getAuthState",
  get: ({ get }) => {
    const token = get(authState);
    if (!token) return false;

    const decoded = JwtDecode(token);
    if (!decoded) return false;

    if (decoded.exp < new Date().getTime() / 1000) {
      return false;
    }
    return true;
  },
});
export const getDecodedJwtState = selector({
  key: "getDecodedJwtState",
  get: ({ get }) => {
    // const token = get(authState);
    // const decoded = JwtDecode(token);
    // decoded.role =
    //   typeof decoded.role === "string" ? [decoded.role] : decoded.role;

    return {role : ["admin"]};
  },
});

export const getUser = selector({
  key: "getUser",
  get: async ({ get }) => {
    const token = get(authState);
    // const decoded = JwtDecode(token);
    const res = await JwtAuthService.get("decoded.sub");
    return res;
  },
});
