import React, { lazy, Suspense } from "react";
import {
  Switch,
  Route,
  Redirect,
  useHistory,
  useParams,
} from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { AppRoutes } from "./appRoutes";
import FormerUtil from "utils/customUtils";

export const AppViews = (props) => {
  const { match } = props;

  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        {AppRoutes.map((r) => (
          <Route
            exact={r.exact}
            key={`${match.url}${r.path}`}
            path={`${match.url}${r.path}`}
            component={lazy(() => import(`${r.component}`))}
          />
        ))}
        <Redirect from={`${match.url}`} to={`${match.url}/home`} />
      </Switch>
    </Suspense>
  );
};

export default AppViews;
