import FormItemInput from "antd/lib/form/FormItemInput";
import JwtDecode from "jwt-decode";
import { AppRoutes } from "views/app-views/appRoutes";

export default class FormerUtil {
  static emptyGuid = "00000000-0000-0000-0000-000000000000";

  static numberFormat(culture = "tr-TR", currency = "try") {
    return new Intl.NumberFormat(culture, {
      style: "currency",
      currency: currency,
    });
  }
  static sortByTerm = function (data, term) {
    return data.sort(function (a, b) {
      return a.indexOf(term) < b.indexOf(term) ? -1 : 1;
    });
  };
  static sortByTermObj = function (data, key, term) {
    return data.sort(function (a, b) {
      return a[key].indexOf(term) < b[key].indexOf(term) ? -1 : 1;
    });
  };

  static isInRole() {
    return true;
    const decoded = JwtDecode(localStorage.TOKEN);
    const roles = [...arguments];
    const arr =
      typeof decoded.role === "string" ? [decoded.role] : [...decoded.role];
    let intersection = arr.filter((x) => roles.includes(x));
    return intersection.length ? true : false;
  }

  static getCurrentRouteInfo() {
    const routes = AppRoutes;
    const path = window.location.pathname;
    const find = routes.find((item) => {
      if (item.path.includes(":id")) {
        const windowPathSplit = path.split("/");
        windowPathSplit.pop();
        const appRouteSplit = item.path.split("/");
        appRouteSplit.pop();
        return `/app${appRouteSplit.join("/")}` === windowPathSplit.join("/");
      } else {
        return `/app${item.path}` === path;
      }
    });
    return find;
  }
}
