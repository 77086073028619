import React, { useState, Suspense } from "react";
import { Menu, Layout, Spin } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import Logo from "./Logo";
import NavSearch from "./NavSearch";
import NavNotification from "./NavNotification";
import NavProfile from "./NavProfile";
import { useRecoilState, useRecoilValue } from "recoil";
import { getHeaderNavColorState } from "../../store/selectors";
import { navCollapsedState, mobileNavToggleState } from "../../store/atoms";
import {
  NAV_TYPE_TOP,
  SIDE_NAV_COLLAPSED_WIDTH,
  SIDE_NAV_WIDTH,
} from "constants/ThemeConstant";
import utils from "utils";

const { Header } = Layout;

export const HeaderNav = (props) => {
  const { navType, isMobile } = props;
  const headerNavColor = useRecoilValue(getHeaderNavColorState);
  const [searchActive, setSearchActive] = useState(false);
  const [navCollapsed, setNavCollapsed] = useRecoilState(navCollapsedState);
  const [mobileNavToggle, setMobileNavToggle] = useRecoilState(
    mobileNavToggleState
  );
  const onSearchClose = () => {
    setSearchActive(false);
  };

  const onToggle = () => {
    if (!isMobile) {
      setNavCollapsed(!navCollapsed);
    } else {
      setMobileNavToggle(!mobileNavToggle);
    }
  };

  const isNavTop = navType === NAV_TYPE_TOP ? true : false;
  const mode = utils.getColorContrast(headerNavColor);

  const getNavWidth = () => {
    if (isNavTop || isMobile) {
      return "0px";
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
    } else {
      return `${SIDE_NAV_WIDTH}px`;
    }
  };
  return (
    <Header
      className={`app-header ${mode}`}
      style={{ backgroundColor: headerNavColor }}
    >
      <div className={`app-header-wrapper ${isNavTop ? "layout-top-nav" : ""}`}>
        <Logo logoType={mode} />
        <div className="nav" style={{ width: `calc(100% - ${getNavWidth()})` }}>
          <div className="nav-left">
            <Menu mode="horizontal">
              {isNavTop && !isMobile ? null : (
                <Menu.Item
                  key="0"
                  onClick={() => {
                    onToggle();
                  }}
                >
                  {navCollapsed || isMobile ? (
                    <MenuUnfoldOutlined className="nav-icon" />
                  ) : (
                    <MenuFoldOutlined className="nav-icon" />
                  )}
                </Menu.Item>
              )}
            </Menu>
          </div>
          <div className="nav-right">
            <NavNotification />
            <NavProfile />
          </div>
          <NavSearch active={searchActive} close={onSearchClose} />
        </div>
      </div>
    </Header>
  );
};

export default HeaderNav;
