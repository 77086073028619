import { selector } from "recoil";
import {
  supplierListState,
  supplierListFilterState,
  processListFilterState,
} from "./supplier.atoms";

export const getSupplierList = selector({
  key: "getSupplierList",
  get: ({ get }) => {
    const list = get(supplierListState);
    const filter = get(supplierListFilterState);
    const filterProcess = get(processListFilterState);
    let filteredItems = list;
    if (filter) {
      filteredItems = list.filter((item) =>
        item.company.toLowerCase().includes(filter.toLowerCase())
      );
    }
    if (filterProcess && filterProcess !== "seciniz") {
      filteredItems = filteredItems.filter((item) =>
        item.processes.includes(filterProcess)
      );
    }
    return filteredItems;
  },
});
