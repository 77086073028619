import {
  SIDE_NAV_LIGHT,
  NAV_TYPE_SIDE,
  SIDE_NAV_DARK,
} from "constants/ThemeConstant";
import { env } from "./EnvironmentConfig";

export const APP_NAME = "Former";
export const API_BASE_URL = env.API_ENDPOINT_URL;
export const SITE_URL = env.SITE_URL;
export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_DARK,
  locale: "tr",
  navType: NAV_TYPE_SIDE,
  topNavColor: "#3e82f7",
  headerNavColor: "#193550",
  mobileNav: false,
};
export const logo =
  "https://former.com.tr/wp-content/uploads/2019/02/former_logo_v2.png";
