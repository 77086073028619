import { selector } from "recoil";
import {
  localeState,
  mobileNavToggleState,
  navTypeState,
  sideNavThemeState,
  topNavColorState,
  headerNavColorState,
  navCollapsedState,
} from "./atoms";

// LAYOUT SELECTORS
export const getLocaleState = selector({
  key: "getLocaleState",
  get: ({ get }) => get(localeState),
});
export const getMobileNavToggleState = selector({
  key: "getMobileNavToggleState",
  get: ({ get }) => get(mobileNavToggleState),
});
export const getNavTypeState = selector({
  key: "getNavTypeState",
  get: ({ get }) => get(navTypeState),
});
export const getSideNavThemeState = selector({
  key: "getSideNavThemeState",
  get: ({ get }) => get(sideNavThemeState),
});

export const getTopNavColorState = selector({
  key: "getTopNavColorState",
  get: ({ get }) => get(topNavColorState),
});

export const getHeaderNavColorState = selector({
  key: "getHeaderNavColorState",
  get: ({ get }) => get(headerNavColorState),
});

export const getnavCollapsedState = selector({
  key: "getnavCollapsedState",
  get: ({ get }) => get(navCollapsedState),
});

// LAYOUT SELECTORS END
