import { atom } from "recoil";

export const supplierListState = atom({
  key: "supplierListState",
  default: [],
});

export const supplierListFilterState = atom({
  key: "supplierListFilterState",
  default: null,
});

export const processListFilterState = atom({
  key: "supplierProcessListFilterState",
  default: null,
});
