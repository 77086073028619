import React, { memo } from "react";
import { Menu, Dropdown, Avatar, Alert, Spin } from "antd";
import {
  EditOutlined,
  SettingOutlined,
  ShopOutlined,
  QuestionCircleOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import Icon from "components/util-components/Icon";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";
import { getUser } from "store/auth.selectors";
import { TOKEN_NAME } from "constants/ApiConstant";
import { useHistory } from "react-router-dom";
import Loading from "components/shared-components/Loading";

const menuItem = [
  {
    title: "Edit Profile",
    icon: EditOutlined,
    path: "/",
  },

  {
    title: "Account Setting",
    icon: SettingOutlined,
    path: "/",
  },
  {
    title: "Billing",
    icon: ShopOutlined,
    path: "/",
  },
  {
    title: "Help Center",
    icon: QuestionCircleOutlined,
    path: "/",
  },
];

export const NavProfile = () => {
  const history = useHistory();
  const signOut = () => {
    localStorage.removeItem(TOKEN_NAME);
    history.push("/auth/login");
  };
  const userLoadable = useRecoilValueLoadable(getUser);
  if (userLoadable.state === "loading") return <Loading />;
  if (userLoadable.state === "hasError")
    return <Alert message="Bir hata oluştu" type="error" />;
  const user = userLoadable.contents;

  const profileImg = "/img/logo-sm.png";
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          {!user ? (
            <Alert message="Bir hata oluştu" type="error" />
          ) : (
            <>
              <Avatar size={45} src={profileImg} />
              <div className="pl-3">
                <h4 className="mb-0">
                  {user.name} {user.surname}
                </h4>
                <span className="text-muted">{user.email}</span>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {/* {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i}>
                <a href={el.path}>
                  <Icon className="mr-3" type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </a>
              </Menu.Item>
            );
          })} */}
          <Menu.Item key={menuItem.legth + 1} onClick={signOut}>
            <span>
              <LogoutOutlined className="mr-3" />
              <span className="font-weight-normal">Çıkış Yap</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item>
          <Avatar src={profileImg} />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

export default NavProfile;
