export const Role = {
  admin: "admin",
  employee: "employee",
  satis: "satıs",
  finans: "finans",
  satinalma: "satınalma",
  uretim: "uretim",
  muhasebe: "muhasebe",
  planlama: "planlama",
  insankaynakları: "insankaynakları",
};
